<template>
  <div>
    <div v-if="!loaded" class="d-flex justify-content-center mb-3">
      <ct-centered-spinner />
    </div>
    <div v-else>
      <b-card>
        <b-card-text>
          <div v-if="phoneServiceTrialEligible">
            <h3 class="mt-0">
              {{ `${product.name} - Free Trial (${product.trial_duration_days} Days)` }}
              &mdash; {{ product.price | dollars }}
            </h3>
            <p>After {{ product.trial_duration_days }} days, the price will be adjusted to $9 per month.</p>
          </div>
          <h3 v-else class="mt-0">
            {{ product.name }} &mdash; {{ product.price | dollars }}
          </h3>

          <div v-if="product.schema">
            <h3 class="text-center">
              Required information
            </h3>

            <schema-form
              ref="productData"
              v-model="productData"
              :fields="product.schema"
            />
          </div>
        </b-card-text>

        <gc-select-phone-number-slide
          class="global-components mb-2"
          :selected-area-code="JSON.stringify(selectedAreaCode)"
          :selected-number="selectedNumber"
          :available-numbers="JSON.stringify(availableNumbers)"
          :total-available-numbers="totalAvailablePhoneNumbers"
          :loading-numbers="loadingNumbers"
          :refreshing="refreshing"
          :saving="saving"
          @areaCodeSelected="handleSelectedAreaCode"
          @numberSelected="handleNumberSelected"
          @changePage="handleChangePage"
        >
          <div slot="loading" class="loading-container mx-auto">
            <ct-centered-spinner />
          </div>
        </gc-select-phone-number-slide>

        <b-button
          variant="primary"
          class="float-right mt-1"
          aria-label="add to cart button"
          :disabled="addToCartButtonDisabled"
          @click="submitted(productData)"
        >
          Add to Cart
        </b-button>
      </b-card>
    </div>
  </div>
</template>

<script>
import { dollars } from '@/filters'
import { mapActions, mapGetters } from 'vuex'
import CtCenteredSpinner from '@/components/shared/CtCenteredSpinner'
import http from '@/http'

export default {
  name: 'PhoneService',

  filters: {
    dollars,
  },

  components: {
    CtCenteredSpinner,
    SchemaForm: () => import('@/components/SchemaForm'),
  },

  props: {
    product: Object,
    company: Object,
  },

  data() {
    return {
      selectedNumber: null,
      productData: {},
      loaded: false,
      loadingNumbers: false,
      refreshing: false,
      saving: false,
      numberPurchased: false,
    }
  },

  computed: {
    ...mapGetters('virtualPhones', [
      'phoneServiceTrialEligible',
      'availableNumbersFinished',
      'availableNumbers',
      'virtualPhones',
      'selectedAreaCode',
      'virtualPhoneToConfigure',
      'totalAvailablePhoneNumbers',
    ]),
    ...mapGetters('checkout', ['cartItems']),

    addToCartButtonDisabled () {
      return (!this.selectedNumber || this.numberPurchased)
    },
  },

  watch: {
    cartItems: {
      handler() {
        this.determinePhoneServiceTrialEligibility(this.company.id)
      },
    },
  },

  async mounted () {
    this.loaded = false
    await this.determinePhoneServiceTrialEligibility(this.company.id)
    this.loaded = true
  },

  methods: {
    ...mapActions('checkout', ['addToCart']),
    ...mapActions('virtualPhones', [
      'determinePhoneServiceTrialEligibility',
      'fetchAvailableNumbers',
      'setSelectedAreaCode',
      'setSelectedAvailableNumber',
      'setVirtualPhoneToConfigureForCompanyOrAccount',
      'setAvailableNumbersPage',
    ]),
    async submitted(productData) {
      if(this.validate(productData)) {
        await this.purchaseNumber()

        if (this.numberPurchased) {
          await this.addToCart({ ...this.product, data: productData })
          this.$emit('completed')
        } else {
          this.$bvToast.toast(
            'Unable to purchase number', 
            { variant: 'danger', title: 'Error' }
          )
        }
      }
    },
    validate() {
      if (!this.$refs.productData) {
        return true
      }

      return this.$refs.productData.form.validate()
    },

    async handleSelectedAreaCode(event) {
      try {
        this.loadingNumbers = true
        this.selectedNumber = null
        const selectedAreaCode = event?.detail?.[0] || {}
        if (selectedAreaCode) await this.setSelectedAreaCode(selectedAreaCode)
        this.loadingNumbers = false
      } catch {
        this.loadingNumbers = false
        this.$bvToast.toast(
          'Unable to retrieve numbers', 
          { variant: 'danger', title: 'Error' }
        )    
      }
    },

    async handleNumberSelected(event) {
      const selectedNumber = event?.detail?.[0] || ''
      if (selectedNumber) this.selectedNumber = selectedNumber
    },

    async handleChangePage(event) {
      this.refreshing = true
      setTimeout(async () => {
        try {
          const page = event?.detail?.[0] || 0
          this.setAvailableNumbersPage(page)
          await this.fetchAvailableNumbers()
        } catch(_error) {
          this.$bvToast.toast(
            'Unable to retrieve numbers', 
            { variant: 'danger', title: 'Error' }
          )        
        } finally {
          this.refreshing = false
        }
      }, 500)
    },

    async purchaseNumber() {
      try {
        const response = await http.post('client/tns_pending_assignment', {
          company_id: this.company.id,
          number: this.selectedNumber,
        }) 

        this.numberPurchased = response.data.success
      } catch {
        this.numberPurchased = false
      }
    },

  },
}
</script>
