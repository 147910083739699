var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    !_vm.loaded
      ? _c(
          "div",
          { staticClass: "d-flex justify-content-center mb-3" },
          [_c("ct-centered-spinner")],
          1
        )
      : _c(
          "div",
          [
            _c(
              "b-card",
              [
                _c("b-card-text", [
                  _vm.phoneServiceTrialEligible
                    ? _c("div", [
                        _c("h3", { staticClass: "mt-0" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                `${_vm.product.name} - Free Trial (${_vm.product.trial_duration_days} Days)`
                              ) +
                              "\n            — " +
                              _vm._s(_vm._f("dollars")(_vm.product.price)) +
                              "\n          "
                          ),
                        ]),
                        _c("p", [
                          _vm._v(
                            "After " +
                              _vm._s(_vm.product.trial_duration_days) +
                              " days, the price will be adjusted to $9 per month."
                          ),
                        ]),
                      ])
                    : _c("h3", { staticClass: "mt-0" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.product.name) +
                            " — " +
                            _vm._s(_vm._f("dollars")(_vm.product.price)) +
                            "\n        "
                        ),
                      ]),
                  _vm.product.schema
                    ? _c(
                        "div",
                        [
                          _c("h3", { staticClass: "text-center" }, [
                            _vm._v(
                              "\n            Required information\n          "
                            ),
                          ]),
                          _c("schema-form", {
                            ref: "productData",
                            attrs: { fields: _vm.product.schema },
                            model: {
                              value: _vm.productData,
                              callback: function ($$v) {
                                _vm.productData = $$v
                              },
                              expression: "productData",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                ]),
                _c(
                  "gc-select-phone-number-slide",
                  {
                    staticClass: "global-components mb-2",
                    attrs: {
                      "selected-area-code": JSON.stringify(
                        _vm.selectedAreaCode
                      ),
                      "selected-number": _vm.selectedNumber,
                      "available-numbers": JSON.stringify(_vm.availableNumbers),
                      "total-available-numbers": _vm.totalAvailablePhoneNumbers,
                      "loading-numbers": _vm.loadingNumbers,
                      refreshing: _vm.refreshing,
                      saving: _vm.saving,
                    },
                    on: {
                      areaCodeSelected: _vm.handleSelectedAreaCode,
                      numberSelected: _vm.handleNumberSelected,
                      changePage: _vm.handleChangePage,
                    },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "loading-container mx-auto",
                        attrs: { slot: "loading" },
                        slot: "loading",
                      },
                      [_c("ct-centered-spinner")],
                      1
                    ),
                  ]
                ),
                _c(
                  "b-button",
                  {
                    staticClass: "float-right mt-1",
                    attrs: {
                      variant: "primary",
                      "aria-label": "add to cart button",
                      disabled: _vm.addToCartButtonDisabled,
                    },
                    on: {
                      click: function ($event) {
                        return _vm.submitted(_vm.productData)
                      },
                    },
                  },
                  [_vm._v("\n        Add to Cart\n      ")]
                ),
              ],
              1
            ),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }